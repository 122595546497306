// src/components/Register.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig'; 
import '../App.css';
import '../responsive.css';

function Register() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [role, setRole] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const register = async () => {
    if (!role) {
      setError('Please select a role to register.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Save the user details in Firestore
      await setDoc(doc(db, "users", user.uid), {
        email: user.email,
        firstName: firstName,
        lastName: lastName,
        company: company,
        phoneNumber: phoneNumber,
        role: role
      });

      // Redirect based on the selected role
      if (role === 'Engineer') {
        navigate('/engineer-dashboard');
      } else if (role === 'Building Owner/PM') {
        navigate('/owner-dashboard');
      }
    } catch (error) {
      console.error('Registration Error:', error);
      setError('Failed to register. Please check your inputs.');
    }
  };

 return (
  <div className="logo-background"> {/* Background Image */}
    <div className="register-container">
      <input 
        type="email" 
        value={email} 
        onChange={(e) => setEmail(e.target.value)} 
        placeholder="Email" 
      />
      <input 
        type="password" 
        value={password} 
        onChange={(e) => setPassword(e.target.value)} 
        placeholder="Password" 
      />
      <input 
        type="text" 
        value={firstName} 
        onChange={(e) => setFirstName(e.target.value)} 
        placeholder="First Name" 
      />
      <input 
        type="text" 
        value={lastName} 
        onChange={(e) => setLastName(e.target.value)} 
        placeholder="Last Name" 
      />
      <input 
        type="text" 
        value={company} 
        onChange={(e) => setCompany(e.target.value)} 
        placeholder="Company" 
      />
      <input 
        type="text" 
        value={phoneNumber} 
        onChange={(e) => setPhoneNumber(e.target.value)} 
        placeholder="Phone Number" 
      />

      {/* Role selection buttons */}
      <div className="role-selection">
        <button 
          className={`role-selector-button ${role === 'Engineer' ? 'selected' : ''}`}
          onClick={() => setRole('Engineer')}>
          Engineer
        </button>
        <button 
          className={`role-selector-button ${role === 'Building Owner/PM' ? 'selected' : ''}`}
          onClick={() => setRole('Building Owner/PM')}>
          Building Owner/PM
        </button>
      </div>
      <div>
        {error && <div className="error-message">{error}</div>} {/* Display error message */}
        <button className="confirm-button" onClick={register}>
          Register
        </button>
      </div>
    </div>
  </div>
);


}

export default Register;
