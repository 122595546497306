// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Adjust the path as necessary

const PrivateRoute = ({ Component }) => {
  const isAuthenticated = !!auth.currentUser; // Check if user is authenticated

  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
