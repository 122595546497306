// src/components/Deficiencies.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Adjust the import path as necessary
import Sidebar from './Sidebar';
import '../App.css'; // Import App.css

const Deficiencies = () => {
  const [fileNames, setFileNames] = useState([]);
  const [user] = useAuthState(auth);

  const navigate = useNavigate();

  const projectButtons = [
    { label: "Home", link: "/", action: () => navigate('/') },
    { label: "Dashboard", link: "/dashboard", action: () => navigate('/dashboard') },
    { label: "Project List", link: "/projects", action: () => navigate('/projects') },
    { label: "Project Photos", link: "/project1", action: () => navigate('/project1') },
    // ... other buttons
  ];

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        try {
          // Adjust the path to point to the specific document
          const docRef = doc(db, 'fileNames', 'list');
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const namesArray = docSnap.data().names; // Assuming 'names' is the field containing the array
            setFileNames(namesArray);
          } else {
            console.log('No such document!');
          }
        } catch (error) {
          console.error('Error fetching file names:', error);
        }
      };

      fetchData();
    }
  }, [user]);

  if (!user) {
    return <div>Please log in to view file names.</div>;
  }

return (
    <div className="DeficienciesPage">
      <header>Inspection Deficiencies:</header>
      <Sidebar buttons={projectButtons} className="sidebar" />
      <div className="main-content">
        <h2>List of Deficiencies</h2>
        {fileNames.map((name, index) => (
          <div key={index}>
            <p>{name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Deficiencies;
