// src/Sidebar.js
import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { Link, useNavigate  } from 'react-router-dom';
import logoImage from '../assets/VilanoLogo.PNG'; // Adjust the path as necessary
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig'; // Import auth instance from firebaseConfig
import '../responsive.css';


const Sidebar = ({ buttons }) => {
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

    const navigate = useNavigate();

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            console.log('Successfully logged out');
            navigate('/login'); // Redirect after successful logout
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setIsSidebarCollapsed(true);
            } else {
                setIsSidebarCollapsed(false);
            }
        };

        // Set the initial state
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
            <img src={logoImage} alt="Logo" className="sidebar-logo" />
            {buttons.map((button, index) => (
                <Link key={index} to={button.link} className="sidebar-button">
                    {button.label}
                </Link>
            ))}
            <button className="sidebar-signout" onClick={handleSignOut}>
                Sign Out
            </button>
        </div>
    );
};

export default Sidebar;
