// src/components/Main.js
import React from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import '../App.css';
import '../responsive.css';


import VilanoLogoImage from '../assets/VilanoLogo.PNG';

function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  const navigateToRegister = () => {
    navigate('/register');
  };

  return (
    <div>
      {/* Background Image */}
      <div className="logo-background"></div>

      {/* Register Button on Top Left */}
      <div className="top-left">
        <button className="register-button" onClick={navigateToRegister}>
          Register
        </button>
      </div>

      {/* Sign In Button on Top Right */}
      <div className="top-right">
        <Link to="/login">
          <button className="login-button">Sign In</button>
        </Link>
      </div>

      {/* Other content of your page goes here */}
    </div>
  );
}

export default Main;
