// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage'; // Import the storage module
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDAX7wDfb1LGRgOLfs20yJ0pryU9zv812o",
  authDomain: "gizmo-7cbee.firebaseapp.com",
  projectId: "gizmo-7cbee",
  storageBucket: "gizmo-7cbee.appspot.com",
  messagingSenderId: "828333429997",
  appId: "1:828333429997:web:6900dc974ba6c715ff28e4",
  measurementId: "G-9NXH42JDSE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Optionally initialize other services like Analytics
const analytics = getAnalytics(app);

// Get a reference to the Firebase Auth object
const auth = getAuth(app);

// Get a reference to the Firebase Storage object
const storage = getStorage(app);

// Initialize Firestore
const db = getFirestore(app); 


export { app, auth, analytics, storage, db }; // Export the storage reference

