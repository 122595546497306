// // src/components/ElectricalData.js
import '../App.css';
import '../responsive.css';
import React, { useState } from 'react';
import { db } from '../firebaseConfig';
import { useAuth } from '../AuthContext';
import { collection, addDoc } from 'firebase/firestore';
import formData from '../assets/Data.json'; // Adjust the path as needed
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';

function MyForm() {
    const [fieldData, setFieldData] = useState(formData);
    const { currentUser } = useAuth(); // Use the current user

    console.log("Current user:", currentUser);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const docRef = await addDoc(collection(db, "dropdown"), fieldData);
            console.log("Document written with ID: ", docRef.id);
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
        {Object.entries(fieldData).map(([key, value]) => (
            <div key={key} style={{ display: 'flex', marginBottom: '10px', alignItems: 'center' }}>
                <label 
                    htmlFor={key} 
                    style={{ 
                        width: '250px', 
                        marginRight: '50px',
                        marginLeft: '50px', 
                        textAlign: 'left', 
                        color: 'black' // Set label font color to black
                    }}
                >
                    {key}
                </label>
                <input
                    type="text"
                    id={key}
                    name={key}
                    value={value || ''}
                    onChange={handleInputChange}
                    style={{ 
                        flex: 1, 
                        color: 'black' // Set input text font color to black
                    }}
                />
            </div>
        ))}
        <button type="submit" style={{ color: 'black' }}>Save Data</button> {/* Optionally set button text color to black */}
    </form>
);

}

const ElectricalData = () => {
    const navigate = useNavigate();

    const projectButtons = [
        { label: "Home", link: "/", action: () => navigate('/') },
        { label: "Dashboard", link: "/dashboard", action: () => navigate('/dashboard') },
        { label: "Project List", link: "/projects", action: () => navigate('/projects') },
        { label: "Deficiencies", link: "/deficiencies", action: () => navigate('/deficiencies') },  
        // ... other buttons
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <header>Building Safety Inspection - Electrical Data:</header>
            <Sidebar buttons={projectButtons} />
                <div className="main-content">
                <MyForm />
            </div>
        </div>
    );
};

export default ElectricalData;



