// src/components/Projects.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useAuth } from '../AuthContext'; // Adjust the path as necessary
import '../App.css'; // Ensure this import is present to include your CSS styles

const ProjectsPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // Using useAuth to access currentUser

  // Placeholder function to determine user's role - you'll need to adjust this
  const getUserRole = (user) => {
    // Assuming the role is stored in some part of the user object, or needs to be fetched
    // This is just a placeholder - you'll need to implement this based on your app's logic
    return user?.role || 'default'; // Adjust how you determine the user's role
  };

  // Function to determine the dashboard path based on the user's role
  const getDashboardPathForRole = async (user) => {
    if (!user) return '/';

    const idTokenResult = await user.getIdTokenResult();
    const role = idTokenResult.claims.role; // Assuming the role is set as a custom claim

    switch (role) {
        case 'admin':
            return '/admin-dashboard';
        case 'engineer':
            return '/engineer-dashboard';
        case 'owner':
            return '/owner-dashboard';
        default:
            return '/';
    }
};


  const projectButtons = [
    {
      label: "Home",
      action: () => navigate(getDashboardPathForRole(currentUser))
    },
    { label: "Project 1", link: "/project1", action: () => navigate('/project1') },
    { label: "Project 2", link: "/project2", action: () => navigate('/project2') },
    { label: "Project 3", link: "/project3", action: () => navigate('/project3') },
    // ... other buttons
  ];

  return (
    <div className="ProjectsPage">
      <header>Projects Page:</header>
      <Sidebar buttons={projectButtons} />
      <div className="main-content">
        {/* Your main content goes here */}
      </div>
    </div>
  );
};

export default ProjectsPage;
