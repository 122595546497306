// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import Main from './Main';
import '../responsive.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Fetch the user's role from Firestore
      const userDoc = await getDoc(doc(db, "users", user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        switch (userData.role) {
          case 'Engineer':
            navigate('/engineer-dashboard');
            break;
          case 'Building Owner/PM':
            navigate('/owner-dashboard');
            break;
          case 'Admin':
            navigate('/admin-dashboard'); // Logic for admins
            break;
          // Removed the default case
        }
      } else {
        console.error('User document does not exist in Firestore.');
        // Handle the scenario where the user document does not exist
        setError('User role is not defined. Please contact support.');
      }
    } catch (error) {
      console.error('Login Error:', error);
      setError('Failed to login. Please check your credentials.');
    }
  };



  return (
    <div>
      <Main />
      <div className="login-container"> {/* Container for styling */}
        <input 
          type="email" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
          placeholder="Email" 
        />
        <input 
          type="password" 
          value={password} 
          onChange={(e) => setPassword(e.target.value)} 
          placeholder="Password" 
        />
        {error && <div className="error-message">{error}</div>} {/* Display error message */}
        <button onClick={handleLogin}>Login</button>
      </div>
    </div>
  );
}

export default Login;
