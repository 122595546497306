// src/components/Project1Page.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import ImageSelector from './ImageSelector'; // Import ImageSelector component
import '../App.css'; // Import App.css

const Project1Page = () => {
  const navigate = useNavigate();

  const projectButtons = [
    { label: "Home", link: "/", action: () => navigate('/') },
    { label: "Dashboard", link: "/dashboard", action: () => navigate('/dashboard') },
    { label: "Project List", link: "/projects", action: () => navigate('/projects') },
    { label: "Deficiencies", link: "/deficiencies", action: () => navigate('/deficiencies') },  
    { label: "Electrical Form", link: "/electrical", action: () => navigate('/electrical') },
    // ... other buttons
  ];

  return (
    <div className="Project1Page">
      <header>Project Photos:</header>
      <Sidebar buttons={projectButtons} />
      <div className="main-content">
        <ImageSelector /> {/* Main content goes here */}
      </div>
    </div>
  );
};

export default Project1Page;
