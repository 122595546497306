// src/firebaseUtils.js
import { auth } from './firebaseConfig'; // Adjust the path to your firebaseConfig file
import { storage } from './firebaseConfig'; // Adjust the path to your firebaseConfig file
import { ref, getDownloadURL, listAll } from 'firebase/storage'; // Combine imports

export const registerUser = async (email, password) => {
  try {
    const userCredential = await auth.createUserWithEmailAndPassword(email, password);
    return userCredential;
  } catch (error) {
    throw error;
  }
};

export const loginUser = async (email, password) => {
  try {
    const userCredential = await auth.signInWithEmailAndPassword(email, password);
    return userCredential;
  } catch (error) {
    throw error;
  }
};

export const fetchImageUrl = async (imagePath) => {
  try {
    const storageRef = ref(storage, imagePath);
    const url = await getDownloadURL(storageRef);
    return url;
  } catch (error) {
    console.error("Error fetching image from Firebase Storage:", error);
    throw error;
  }
};

export const fetchImageList = async () => {
  try {
    const listRef = ref(storage, 'images/'); // Reference to the images folder
    const res = await listAll(listRef);
    return res.items; // Array of references to the files in the images folder
  } catch (error) {
    console.error("Error fetching image list:", error);
    throw error;
  }
};

