// src/components/owner-dashboard.js
import React from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import '../App.css'; // Ensure this import is present to include your CSS styles
import '../responsive.css';


const OwnerDashboardPage = () => {
  const navigate = useNavigate();

  const dashboardButtons = [
    { label: "Home", link: "/owner-dashboard", action: () => navigate('/owner-dashboard') },
    { label: "Projects", link: "/projects", action: () => navigate('/projects') },
    // ... other buttons
  ];

  return (
    <div className="DashboardPage">
      <header>Owner and Property Manager Dashboard:</header>
      <Sidebar buttons={dashboardButtons} />
      <div className="main-content">
        {/* Your main content goes here */}
      </div>
    </div>
  );
}

export default OwnerDashboardPage;
