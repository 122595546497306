// src/components/ImageSelector.js
import React, { useEffect, useState } from 'react';
import { fetchImageList } from '../firebaseUtils';
import { ref, getDownloadURL } from 'firebase/storage';
import { storage } from '../firebaseConfig';
import './Photos.css'; // Adjust path if necessary

const ImageSelector = () => {
  const [imageList, setImageList] = useState([]);
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const loadImageUrls = async () => {
      try {
        const items = await fetchImageList();
        const urlPromises = items.map(item => 
          getDownloadURL(ref(storage, item.fullPath))
        );

        const urls = await Promise.all(urlPromises);
        const newImageUrls = urls.reduce((acc, url, index) => {
          acc[items[index].name] = url;
          return acc;
        }, {});

        setImageList(items);
        setImageUrls(newImageUrls);
      } catch (error) {
        console.error('Failed to load images:', error);
      }
    };

    loadImageUrls();
  }, []);

  const handleImageSelect = (imageUrl) => {
    window.open(imageUrl, '_blank');
  };

  const removeExtension = (filename) => {
    // Split the filename on '.' and remove the last part (extension)
    const parts = filename.split('.');
    parts.pop(); // Remove the extension part
    return parts.join('.'); // Rejoin the remaining parts
  };

  return (
    <div className="image-container">
      {imageList.map(imageRef => (
        <div key={imageRef.name} onClick={() => handleImageSelect(imageUrls[imageRef.name])} className="image-thumbnail">
          <img src={imageUrls[imageRef.name]} alt={imageRef.name} className="image-thumb" />
          <div className="image-caption">{removeExtension(imageRef.name)}</div>
        </div>
      ))}
    </div>
  );
};

export default ImageSelector;
