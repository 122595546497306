// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Main from './components/Main';
import PrivateRoute from './components/PrivateRoute';
import Login from './components/Login';
import Register from './components/Register';
import AdminDashboard from './components/admin-dashboard';
import OwnerDashboard from './components/owner-dashboard';
import EngDashboard from './components/engineer-dashboard';
import Projects from './components/Projects';
import Project1 from './components/Project1';
import Project2 from './components/Project2';
import Project3 from './components/Project3';
import Sidebar from './components/Sidebar'; 
import Deficiencies from './components/Deficiencies'; 
import Electrical from './components/ElectricalData'; 
import { AuthProvider } from './AuthContext'; // Import AuthProvider


function App() {
  return (
    <AuthProvider> {/* Wrap your Router with AuthProvider */}
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/admin-dashboard" element={<PrivateRoute Component={AdminDashboard} />} />
            <Route path="/owner-dashboard" element={<PrivateRoute Component={OwnerDashboard} />} />
            <Route path="/engineer-dashboard" element={<PrivateRoute Component={EngDashboard} />} />
            <Route path="/projects" element={<PrivateRoute Component={Projects} />} />
            <Route path="/project1" element={<PrivateRoute Component={Project1} />} />
            <Route path="/project2" element={<PrivateRoute Component={Project2} />} />
            <Route path="/project3" element={<PrivateRoute Component={Project3} />} />
            <Route path="/deficiencies" element={<PrivateRoute Component={Deficiencies} />} />
            <Route path="/electrical" element={<PrivateRoute Component={Electrical} />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
